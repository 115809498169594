<template>
	<div>
		<t-modal name="contact-list-modal">
			<list-contact
				@add="openAddContactModal"
				@invited="addInvitations"
				:isCommand="true"
				:userId="user ? user.id : null"
				:userName="user ? user.name : null"
				:restaurantName="restaurantName ? restaurantName : null"
				:planifiedAt="form ? form.planifiedFor : null"
			/>
		</t-modal>
		<t-modal name="add-contact-modal">
			<add-contact @submit="openModal" @back="openModal" />
		</t-modal>
		<form @input="handleInput" class="osahan-product">
			<div class="product-details">
				<div
					v-if="withTableSelector"
					class="bg-white shadow-sm rounded"
				>
					<div class="text-center pt-2 text-grey-600">
						Please choose your table
					</div>
					<span
						class="text-danger"
						v-if="
							$root.errors.addressForm &&
								$root.errors.addressForm.tableId
						"
					>
						{{ $root.errors.addressForm.tableId.join(',') }}
					</span>
					<div class="flex flex-wrap">
						<button
							type="button"
							class="w-24 p-2 relative"
							@click="setTable(table.id)"
							v-for="(table, key) in tables"
							:key="key"
						>
							<img :src="dishImage" />
							<h4
								:class="[
									'absolute top-center left-center text-grey-500 bg-white rounded-full w-8 h-8 text-3',
									selectedTable.id == table.id
										? 'bg-magenta text-white'
										: ''
								]"
							>
								<span
									style="font-size: 100%;"
									class="absolute top-center left-center"
								>
									{{ table.number }}
								</span>
							</h4>
						</button>
					</div>
				</div>
				<div
					v-if="selectedTable && selectedTable.comment"
					class="p-3 bg-white my-2 shadow-sm rounded"
				>
					{{ selectedTable.comment }}
				</div>
				<div class="details">
					<div class="p-3 bg-white my-2 shadow-sm rounded">
						<div class="d-flex align-items-center">
							<div
								class="btn-group osahan-radio btn-group-toggle"
								data-toggle="buttons"
							>
								<label>
									Number of guests
								</label>
							</div>

							<a class="ml-auto">
								<div
									class="input-group input-spinner ml-auto cart-items-number"
								>
									<div class="input-group-prepend">
										<button
											class="btn btn-success btn-sm"
											type="button"
											@click="incrementInvitationNumber"
										>
											+
										</button>
									</div>
									<input
										type="text"
										class="form-control"
										v-model="form.invitationNumber"
									/>
									<div class="input-group-append">
										<button
											class="btn btn-success btn-sm"
											type="button"
											@click="decrementInvitationNumber"
										>
											−
										</button>
									</div>
								</div>
							</a>
						</div>
					</div>
					<div
						v-if="withTableSelector"
						class="p-3 bg-white my-2 shadow-sm rounded"
					>
						<div class="d-flex align-items-center">
							<div
								class="btn-group osahan-radio btn-group-toggle"
								data-toggle="buttons"
							>
								<label>
									Booking Date
								</label>
							</div>

							<a class="ml-auto">
								<div class="input-group input-spinner">
									<t-datepicker v-model="form.planifiedFor" />
								</div>
							</a>
						</div>
						<span
							class="text-danger"
							v-if="
								$root.errors.addressForm &&
									$root.errors.addressForm.planifiedFor
							"
						>
							{{
								$root.errors.addressForm.planifiedFor.join(',')
							}}
						</span>
					</div>
					<div
						v-if="user"
						class="p-3 bg-white my-2 shadow-sm rounded"
					>
						<div class="d-flex align-items-center">
							<div
								class="btn-group osahan-radio btn-group-toggle"
								data-toggle="buttons"
							>
								<label>
									Invite
								</label>
							</div>

							<a class="ml-auto" @click="openModal">
								<div
									class="input-group input-spinner ml-auto cart-items-number"
								>
									<div class="input-group-prepend">
										<button
											class="btn btn-success btn-sm"
											type="button"
										>
											+
										</button>
									</div>
								</div>
							</a>
						</div>
						<div
							v-if="form.invited && form.invited.length > 0"
							class="p-3 my-2"
						>
							<div>Invitations:</div>
							<div class="w-full flex flex-wrap">
								<div
									v-for="(email, key) in form.invited"
									:key="key"
									class="rounded-full shadow-sm p-2 m-1"
								>
									{{ email }}
								</div>
							</div>
						</div>
					</div>
					<div
						v-if="withTableSelector"
						class="p-3 bg-white my-2 shadow-sm rounded"
					>
						<div class="d-flex align-items-center">
							<div
								class="btn-group osahan-radio btn-group-toggle"
								data-toggle="buttons"
							>
								<label>
									Available time slots
								</label>
							</div>

							<a class="ml-auto w-72 py-2 overflow-auto">
								<div
									class="btn-group osahan-radio btn-group-toggle"
									data-toggle="buttons"
								>
									<label
										v-for="(hour, key) in times"
										:key="key"
										:class="[
											'btn border-2 border-magenta text-magenta',
											form.time == hour
												? 'bg-magenta text-white'
												: ''
										]"
									>
										<input
											type="radio"
											v-model="form.time"
											:value="hour"
										/>
										{{ hour }}
									</label>
								</div>
							</a>
						</div>
					</div>
					<textarea
						type="text"
						class="shadow-none form-control form-control-lg py-2 px-4 input-group rounded overflow-hidden bg-white border-1 border-grey-300 min-h-24"
						placeholder="Add a comment"
						aria-label=""
						v-model="form.comment"
						aria-describedby="basic-addon1"
					/>
					<span class="text-danger" v-if="$root.errors.comment">
						{{ $root.errors.comment.join(',') }}
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import ListContact from '@/components/ListContact';
import AddContact from '@/components/AddContact';
import { ApiGetRestaurantTables } from '@/tools/apiService';
import { formatDateForForm } from '@/tools/helpers';
import { mapGetters } from 'vuex';

export default {
	props: ['restaurantId', 'restaurantName', 'withTableSelector'],
	data: () => ({
		dishImage: require('@/assets/images/dish-plate.png'),
		isModalOpen: false,
		times: [],
		tables: [],
		form: {
			invitationNumber: 1,
			planifiedFor: new Date(),
			time: null
		}
	}),
	components: {
		ListContact,
		AddContact
	},
	mounted: function() {
		this.times = this.$constants.ACTIVE_TIMES;
		this.form.time = this.$constants.ACTIVE_TIMES[0];
		this.getTables();
	},
	computed: {
		...mapGetters({
			user: 'auth/user'
		}),
		selectedTable: function() {
			let table = this.tables.find(el => el.id == this.form.tableId);
			return table ? table : {};
		}
	},
	methods: {
		incrementInvitationNumber: function() {
			if (this.form.invitationNumber < 100)
				this.form.invitationNumber += 1;
		},
		decrementInvitationNumber: function() {
			if (this.form.invitationNumber > 0) this.form.invitationNumber -= 1;
		},
		addInvitations: function(invited) {
			this.$set(this.form, 'invited', invited);
			this.closeModal();
			this.closeAddContactModal();
		},
		setTable: function(tableId) {
			if (this.form.tableId == tableId)
				this.$set(this.form, 'tableId', null);
			else this.$set(this.form, 'tableId', tableId);
			this.handleInput();
		},
		openModal: function() {
			// this.closeAddContactModal();
			this.$modal.show('contact-list-modal', {});
		},
		closeModal: function() {
			this.$modal.hide('contact-list-modal');
		},
		openAddContactModal: function() {
			// this.closeModal();
			this.$modal.show('add-contact-modal', {});
		},
		closeAddContactModal: function() {
			this.$modal.hide('add-contact-modal');
		},
		getTables: function() {
			ApiGetRestaurantTables(this.restaurantId).then(response => {
				this.tables = response.data.tables;
			});
		},
		handleInput() {
			this.$emit('input', this.buildSubmitObj());
		},
		buildSubmitObj: function() {
			let reservedAt = new Date(this.form.planifiedFor);
			reservedAt.setHours(this.form.time.split(':')[0]);
			reservedAt.setMinutes(this.form.time.split(':')[1]);

			let form = {};
			form.planifiedFor = formatDateForForm(reservedAt);
			form.comment = this.form.comment ? this.form.comment : '---';
			if (this.form.invitationNumber > 0)
				form.comment += `\n Nombre des invités ${this.form.invitationNumber}`;
			if (this.form.invited && this.form.invited.length > 0)
				form.comment += `\n les invités ${this.form.invited.join(',')}`;
			form.tableId = this.form.tableId;
			form.address = 'N/A';
			form.city = 'N/A';
			form.code_postal = 'N/A';
			form.country = 'N/A';
			form.region = 'N/A';
			form.lat = 0;
			form.lng = 0;

			return form;
		}
	}
};
</script>

<style></style>
